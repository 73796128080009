import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ShopContext from "./context/ShopContext";
import CategoriesSidebar from "./CategoriesSidebar";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

const SingleProductView = (props) => {
  var productId = props.match.params.id;

  const context = useContext(ShopContext);

  var product = context.products.find((d) => d.id == productId);
  //var category = context.categories.find((d) => d.pcat_id == product.);

  return (
    <React.Fragment>
    <CategoriesSidebar/>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="row">
          <aside className="col-lg-6 mb-3">
            <img
              className="img-fluid img-thumbnail"
              alt=""
              src={"/" + product.id + ".jpg"}
            />
          </aside>
          <div className="col-lg-6 mb-3">
            <article className="ps-lg-3">
              <h2 className="title text-primary">{product.productName}</h2>
              <h4>{product.price}€</h4>
              <p>{product.description}</p>
              <hr />
              <dl className="row">
                <dt className="col-3">Kogus</dt>
                <dd className="col-9">{product.amount}kg</dd>
                <dt className="col-3">Kategooria</dt>
                <dd className="col-9">{product.pcat}</dd>
                <dt className="col-3">Limiit</dt>
                <dd className="col-9">{product.pcat_limit}kg</dd>
                <dt className="col-3">Tarneaeg</dt>
                <dd className="col-9">{product.delivery} päeva</dd>
              </dl>
              <button
                onClick={context.addProductToCart.bind(this, product)}
                href="/"
                className="btn btn-primary"
              >
                <i className="me-2 bi-basket"></i> Lisa korvi{" "}
              </button>
            </article>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withRouter(SingleProductView);
