import React, { useContext } from "react";
import ShopContext from "./context/ShopContext";
import CategoriesSidebar from "./CategoriesSidebar";
import CartItemsRender from "./components/CartItemsRender";

const CartPage = (props) => {
  const context = useContext(ShopContext);

  return (
    <React.Fragment>
      <CategoriesSidebar />

      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2 className="text-primary">Ostukorv</h2>

        {context.cart.length <= 0 && (
          <div className="alert alert-primary m-5" role="alert">
            Ostukorv on hetkel tühi.
          </div>
        )}

        {context.cart.length > 0 && <CartItemsRender />}
      </main>
    </React.Fragment>
  );
};

export default CartPage;
