import React from "react";
import ShopContext from "./context/ShopContext";
import { Link } from "react-router-dom";
import CategoriesSidebar from "./CategoriesSidebar";

const ProductsPage = (props) => {
  return (
    <ShopContext.Consumer>
      {(context) => (
        <React.Fragment>
          <div className="row">
            <CategoriesSidebar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <h2 className="text-primary">Tooted</h2>
              <div className="row">
                {context.products.map((product) => (
                  <div
                    key={product.id}
                    className="col-xl-3 col-lg-4 col-sm-6 col-12"
                  >
                    <div className="card card-product-grid">
                      <div className="img-wrap">
                        <Link to={`/toode/${product.id}`}>
                          <img
                            alt={product.productName}
                            src={"/" + product.id + ".jpg"}
                          />
                        </Link>
                      </div>
                      <div className="info-wrap">
                        <p className="title">{product.productName}</p>
                      </div>
                      <div className="bottom-wrap">
                        <button
                          className="btn btn-primary float-end"
                          onClick={context.addProductToCart.bind(this, product)}
                        ><i className="me-2 bi-basket"></i>
                          Lisa korvi
                        </button>
                        <div className="price-wrap lh-sm">
                          <strong className="price">{product.price.toFixed(2)}€</strong>
                          <br />
                          <small className="text-muted"></small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </main>
          </div>
        </React.Fragment>
      )}
    </ShopContext.Consumer>
  );
};

export default ProductsPage;
