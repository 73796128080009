import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ShopContext from "./context/ShopContext";
import CategoriesSidebar from "./CategoriesSidebar";
import { Link } from "react-router-dom";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

const ProductCategories = (props) => {
  var categoryId = props.match.params.id;

  const context = useContext(ShopContext);

  var currentCategory = context.products.find((d) => d.pcat_id == categoryId);

  const filteredProducts = context.products.filter(
    (item) => item.pcat_id == categoryId
  );

  return (
    <React.Fragment>
      <div className="row">
        <CategoriesSidebar />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2 className="text-primary">
            Tooted<i className="bi-chevron-compact-right"></i>
            {currentCategory ? currentCategory.pcat : ""}
          </h2>

            {currentCategory == undefined && (
              <div className="alert alert-primary m-5" role="alert">
                Tooteid ei leitud.
              </div>
            )}

          <div className="row">

            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="col-xl-3 col-lg-4 col-sm-6 col-12"
              >
                <div className="card card-product-grid">
                  <div className="img-wrap">
                    <Link to={`/toode/${product.id}`}>
                      <img
                        alt={product.productName}
                        src={"/" + product.id + ".jpg"}
                      />
                    </Link>
                  </div>

                  <div className="info-wrap">
                    <p className="title">{product.productName}</p>
                  </div>
                  <div className="bottom-wrap">
                    <button
                      className="btn btn-primary float-end"
                      onClick={context.addProductToCart.bind(this, product)}
                    ><i className="me-2 bi-basket"></i>
                      Lisa korvi
                    </button>

                    <div className="price-wrap lh-sm">
                      <strong className="price">{product.price.toFixed(2)}€</strong>
                      <br />
                      <small className="text-muted"></small>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductCategories);
