import React from "react";

export default React.createContext({
  products: [],
  categories: [],
  cart: [],
  cartTotals: {},
  addProductToCart: (product) => {},
  removeProductFromCart: (productId) => {},
  incrementQuantity: (prductId) => {},
  decrementQuantity: (prductId) => {},
});
