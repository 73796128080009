import React, { useContext, useEffect } from "react";
import ShopContext from "./context/ShopContext";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Menu = (props) => {
  const context = useContext(ShopContext);

  const location = useLocation();

  
  useEffect(() => {
    var nav = document.getElementById('navbarCollapse');
    var sidebarMenu = document.getElementById('sidebarMenu');

    nav.classList.remove('show');
    sidebarMenu.classList.remove('show');
  }, [location]);

  var cartItemNumber = context.cart.reduce((count, curItem) => {
    return count + curItem.quantity;
  }, 0);

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-primary bg-gradient">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            E-pood
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".my-collapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse my-collapse"
            id="navbarCollapse"
          >
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Tooted
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Juhend
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/ostukorv">
                  Ostukorv ({cartItemNumber})
                </Link>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Otsi"
                aria-label="Otsi"
              />
              <button className="btn btn-outline-light" type="button">
                Otsi
              </button>
            </form>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Menu;
