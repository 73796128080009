import React, { useEffect } from "react";
import Menu from "./Menu";
import ShoppingCart from "./ShoppingCart";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import NoMatchPage from "./NoMatchPage";
import Products from "./Products";
import SingleProductView from "./SingleProductView";
import GlobalState from "./context/GlobalState";
import ProductCategories from "./ProductCategories";

const App = (props) => {
  document.body.classList.add("bg-light");

  useEffect(() => {
    document.title = "E-pood";
  }, []);

  return (
    <GlobalState>
      <BrowserRouter>
        <Menu />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css"
        ></link>
        <div className="container-fluid">
          <Routes>
            <Route path="" element={<Products />} />
            <Route path="ostukorv" element={<ShoppingCart />} />
            <Route path="*" element={<NoMatchPage />} />
            <Route exact path="/toode/:id" element={<SingleProductView />} />
            <Route
              exact
              path="/kategooria/:id"
              element={<ProductCategories />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </GlobalState>
  );
};

export default App;
