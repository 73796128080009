import React from "react";
import ShopContext from "./context/ShopContext";
import { Link } from "react-router-dom";

const CategoriesSidebar = (props) => {
  return (
    <ShopContext.Consumer>
      {(context) => (
        <React.Fragment>
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block sidebar collapse my-collapse"
          >
            <div className="position-sticky pt-3 sidebar-sticky">
              <ul className="nav flex-column">
                {context.categories.map((category) => (
                  <li key={category.id} className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to={`/kategooria/${category.id}`}
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </React.Fragment>
      )}
    </ShopContext.Consumer>
  );
};

export default CategoriesSidebar;
