import React, { useReducer } from "react";
import ShopContext from "./ShopContext";
import {
  shopReducer,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  DECREMENT_QUANTITY,
} from "../HandleCart";
import productData from "../product_data.json";

const GlobalState = (props) => {
  const products = productData.products;
  const productCategories = productData.pcats;
  const [cartState, dispatch] = useReducer(shopReducer, { cart: [] });
  var cartTotal = 0.0;
  var VAT = 0.0;
  var withoutVAT = 0.0;

  cartState.cart.map(
    (cartItem) => (
      (cartTotal += Number(cartItem.quantity) * Number(cartItem.price)),
      (cartTotal = Number(parseFloat(cartTotal)))
    )
  );

  VAT = (cartTotal - cartTotal / 1.2).toFixed(2);
  withoutVAT = (cartTotal / 1.2).toFixed(2);
  cartTotal = cartTotal.toFixed(2);
  
  var cartTotals = {
    "vat": VAT,
    "withoutVAT": withoutVAT,
    "total": cartTotal
  };

  const addProductToCart = (product) => {
    setTimeout(() => {
      dispatch({ type: ADD_PRODUCT, product: product });
    }, 300);
  };

  const removeProductFromCart = (productId) => {
    setTimeout(() => {
      dispatch({ type: REMOVE_PRODUCT, productId: productId });
    }, 300);
  };

  const decrementQuantity = (productId) => {
    setTimeout(() => {
      dispatch({ type: DECREMENT_QUANTITY, productId: productId });
    }, 300);
  };

  return (
    <ShopContext.Provider
      value={{
        products: products,
        categories: productCategories,
        cart: cartState.cart,
        cartTotals: cartTotals,
        addProductToCart: addProductToCart,
        removeProductFromCart: removeProductFromCart,
        incrementQuantity: addProductToCart,
        decrementQuantity: decrementQuantity,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default GlobalState;
