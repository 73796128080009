import React, { useContext } from "react";
import ShopContext from "../context/ShopContext";
import { Link } from "react-router-dom";

const CartPage = (props) => {
  const context = useContext(ShopContext);

  return (
    <React.Fragment>
        <div className="row">
          <div className="col-lg-9">
            <div className="card">
              <div className="card-body">

                {context.cart.map((cartItem) => (
                  <article key={cartItem.key} className="row gy-3 mb-4">
                    <div className="col-lg-6">
                      <div className="itemside me-lg-5">
                        <div className="aside">
                          <img
                            alt={cartItem.productName}
                            src={cartItem.id + ".jpg"}
                            className="img-sm img-thumbnail order rounded"
                          />
                        </div>
                        <div className="info">
                          <Link
                            to={`/toode/${cartItem.id}`}
                            className="title mb-"
                          >
                            {cartItem.productName}
                          </Link>
                          <p className="text-muted small">
                            {cartItem.pcat}, Ühiku kogus {cartItem.amount}kg,
                            Limiit - {cartItem.pcat_limit}kg
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="input-group input-spinner">
                        <button
                          className="btn btn-light"
                          type="button"
                          onClick={context.decrementQuantity.bind(
                            this,
                            cartItem.id
                          )}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#999"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 13H5v-2h14v2z"></path>
                          </svg>
                        </button>
                        <input
                          type="text" readOnly
                          className="form-control"
                          value={cartItem.quantity}
                        />
                        <button
                          className="btn btn-light"
                          type="button" 
                          onClick={context.addProductToCart.bind(
                            this,
                            cartItem
                          )}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#999"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-2 col-sm-4 col-6">
                      <div className="price-wrap lh-sm">
                        <var className="price h6">
                          {(cartItem.quantity * cartItem.price).toFixed(2)}€
                        </var>{" "}
                        <br />
                        <small className="text-muted">
                          {" "}
                          {cartItem.price.toFixed(2)}€ / tk
                        </small>
                      </div>
                    </div>
                    <div className="col-lg col-sm-4">
                      <div className="float-lg-end">
                        <button
                          href="#"
                          className="btn btn-icon btn-light"
                          onClick={context.removeProductFromCart.bind(
                            this,
                            cartItem.id
                          )}
                        >
                          <i className="bi-trash"></i>
                        </button>
                      </div>
                    </div>
                  </article>
                ))}
              </div>

              {context.cart.length > 0 && (
                <div className="card-body border-top">
                  <p className="mb-0">
                    <i className="me-2 text-success fa fa-truck"></i>
                    Kättetoimetamise aeg: 3 päeva
                  </p>
                </div>
              )}
            </div>
          </div>

          {context.cart.length > 0 && (
            <aside className="col-lg-3 mt-3 mt-lg-0">
              <div className="card">
                <div className="card-body">
                  <dl className="dlist-align">
                    <dt>Summa ilma km:</dt>
                    <dd className="text-end">
                      {context.cartTotals.withoutVAT}€
                    </dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Käibemaks:</dt>
                    <dd className="text-end">{context.cartTotals.vat}€</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Summa:</dt>
                    <dd className="text-end">{context.cartTotals.total}€</dd>
                  </dl>
                  <hr />
                  <dl className="dlist-align">
                    <dt>Transport:</dt>
                    <dd className="text-end">0.00€</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Kokku:</dt>
                    <dd className="text-end text-dark h5">
                      {context.cartTotals.total}€
                    </dd>
                  </dl>
                  <div className="d-grid gap-2 my-3">
                    <a href="#" className="btn btn-primary w-100">
                      {" "}
                      Kinnita ost{" "}
                    </a>
                    <Link className="btn btn-light w-100" to="/">
                      Tagasi poodi{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </aside>
          )}
        </div>
    </React.Fragment>
  );
};

export default CartPage;
